import React from 'react'

import imgLoader from 'assets/img/spin.gif'
import './styles.css'

export default function Loader() {
    return (
        <>
            <div className="loader">
                <img src={imgLoader} alt="Loader" />
            </div>
        
        </>
    )
}
