import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";

import iconeClaro from "assets/img/icone-claro.svg"
import Loader from 'Components/Loader/Loader';
import PoliticaPrivacidade from 'Components/PoliticaPrivacidade/PoliticaPrivacidade';


export default function ContactUs({ politicaPriv, setPoliticaPriv, toggleIdioma }) {
    const [loaderOn, setLoaderOn] = useState(false)
    const [resForm, setResForm] = useState(false)
    const [resError, setResError] = useState(false)




    function sendEmail(e) {
        e.preventDefault();
        setLoaderOn(true)

        emailjs.sendForm('service_8jtpf5j', 'template_5l74wys', e.target, 'user_Kzo7ZpTmewJPNkgdUQcWu')
            .then((result) => {
                console.log(result.text);
                setResForm(true)
                setLoaderOn(false)
            }, (error) => {
                console.log(error.text);
                setResError(true)
                setLoaderOn(false)
            });
        e.target.reset()
    };

    function onChange(value) {
        console.log("Captcha value:", value);
    }

    return (
        <>
            {
                !toggleIdioma ?
                    //Texto PT

                    <section className="formulario">

                        <h2 className="titulo-2 formulario__titulo">Contacte-nos</h2>
                        {
                            resForm &&
                            <div className="alertForm" >
                                <p style={{ color: "green" }}>E-mail enviado com sucesso!</p>
                            </div>
                        }

                        {
                            resError &&
                            <div className="alertForm" >
                                <p style={{ color: "red" }}>Verifique os dados e tente novamente!</p>
                            </div>
                        }

                        <form className="formulario__form" onSubmit={sendEmail}>

                            <div className='container-formulario'>
                                <div className='form-row'>
                                    <div className='item itemNome'>
                                        <label htmlFor="form__nome">Nome*</label>
                                        <input required type="text" id="form__nome" name="user_name" />
                                    </div>
                                </div>

                                <div className='form-row'>
                                    <div className='itema'>
                                        <label htmlFor="form__e-mail">E-mail*</label>
                                        <input required type="email" id="form__e-mail" name="user_email" />
                                    </div>
                                    <div className='itemb'>
                                        <label htmlFor="form__contato">Telefone*</label>
                                        <input required type="tel" id="form__contato" maxLength="15" name="user_contacto" />
                                    </div>
                                </div>

                                <div className='form-row'>
                                    <div className='itema'>
                                        <label htmlFor="form__tipo-evento">Tipo de Evento*</label>

                                        <select required type="email" id="form__tipo-evento" name="user_evento">
                                            <option value="opcao 0">Selecione</option>
                                            <option value="Cerimónia Religiosa">Cerimónia Religiosa</option>
                                            <option value="Cerimónia Civil">Cerimónia Civil</option>
                                            <option value="Corte do Bolo">Corte do Bolo</option>
                                            <option value="Outros">Outros</option>
                                        </select>
                                    </div>
                                    <div className='itemb'>
                                        <label htmlFor="form__data">Data do Evento*</label>
                                        <input required type="date" id="form__data" name="user_data" />
                                    </div>
                                </div>

                                <div className='form-row form-row-ms'>
                                    <div className='item-mensagem'>
                                        <label htmlFor="form__mensagem">Mensagem*</label> <br />
                                        <textarea required id="form__mensagem" name="user_mensagem"></textarea>
                                    </div>
                                </div>

                                <div className='form-row'>
                                    <ReCAPTCHA
                                        sitekey="6LclUvIdAAAAALm4x0PDOM63z-aD0lHYrcVcNW-7"
                                        onChange={onChange}
                                        size="invisible"
                                    />
                                </div>

                                <div className='form-row'>
                                    <div className='itema itemPolitcasA'>
                                        <div className="form__aceitaPolitica">
                                            <input type="checkbox" id="form__aceitaPolitica" required name="aceitaPolitica" />
                                            <label htmlFor="form__aceitaPolitica">
                                                Aceito a vossa
                                                <a type="button"
                                                    onClick={() => setPoliticaPriv(!politicaPriv)}
                                                > Política de Gestão da
                                                    Privacidade.</a>
                                            </label>
                                        </div>
                                    </div>
                                    <div className='itemb itemPolitcasB'>
                                        <div className="form__botao">
                                            <button type="submit" value="Enviar">Enviar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <address className="formulario__endereco">
                            <p className="endereco__local">
                                Rua Godinho, 529<br />
                                4450-149 Matosinhos<br />
                                Portugal
                            </p>
                            <p className="endereco__email">
                                <a href='mailto:info@bellcanto.pt'>info@bellcanto.pt</a><br />
                                <span>+351 965 386 465 |</span> +351 917 385 130
                                <br />
                                <a href='https://www.instagram.com/bellcantomusic/' target="_blank" rel='noreferrer'>@bellcantomusic</a>
                            </p>
                            <img src={iconeClaro} alt="" className="endereco__img" />
                        </address>
                        {
                            loaderOn &&
                            <Loader />
                        }


                    </section>

                    :
                    //Texto EN
                    <section className="formulario">

                        <h2 className="titulo-2 formulario__titulo">Contacte-nos</h2>
                        {
                            resForm &&
                            <div className="alertForm" >
                                <p style={{ color: "green" }}>E-mail enviado com sucesso!</p>
                            </div>
                        }

                        {
                            resError &&
                            <div className="alertForm" >
                                <p style={{ color: "red" }}>Verifique os dados e tente novamente!</p>
                            </div>
                        }

                        <form className="formulario__form" onSubmit={sendEmail}>

                            <div className='container-formulario'>
                                <div className='form-row'>
                                    <div className='item itemNome'>
                                        <label htmlFor="form__nome">Nome*</label>
                                        <input required type="text" id="form__nome" name="user_name" />
                                    </div>
                                </div>

                                <div className='form-row'>
                                    <div className='itema'>
                                        <label htmlFor="form__e-mail">E-mail*</label>
                                        <input required type="email" id="form__e-mail" name="user_email" />
                                    </div>
                                    <div className='itemb'>
                                        <label htmlFor="form__contato">Telefone*</label>
                                        <input required type="tel" id="form__contato" maxLength="15" name="user_contacto" />
                                    </div>
                                </div>

                                <div className='form-row'>
                                    <div className='itema'>
                                        <label htmlFor="form__tipo-evento">Tipo de Evento*</label>

                                        <select required type="email" id="form__tipo-evento" name="user_evento">
                                            <option value="opcao 0">Selecione</option>
                                            <option value="Cerimónia Religiosa">Cerimónia Religiosa</option>
                                            <option value="Cerimónia Civil">Cerimónia Civil</option>
                                            <option value="Corte do Bolo">Corte do Bolo</option>
                                            <option value="Outros">Outros</option>
                                        </select>
                                    </div>
                                    <div className='itemb'>
                                        <label htmlFor="form__data">Data do Evento*</label>
                                        <input required type="date" id="form__data" name="user_data" />
                                    </div>
                                </div>

                                <div className='form-row form-row-ms'>
                                    <div className='item-mensagem'>
                                        <label htmlFor="form__mensagem">Mensagem*</label> <br />
                                        <textarea required id="form__mensagem" name="user_mensagem"></textarea>
                                    </div>
                                </div>

                                <div className='form-row'>
                                    <ReCAPTCHA
                                        sitekey="6LclUvIdAAAAALm4x0PDOM63z-aD0lHYrcVcNW-7"
                                        onChange={onChange}
                                        size="invisible"
                                    />
                                </div>

                                <div className='form-row'>
                                    <div className='itema itemPolitcasA'>
                                        <div className="form__aceitaPolitica">
                                            <input type="checkbox" id="form__aceitaPolitica" required name="aceitaPolitica" />
                                            <label htmlFor="form__aceitaPolitica">
                                                Aceito a vossa
                                                <a type="button"
                                                    onClick={() => setPoliticaPriv(!politicaPriv)}
                                                > Política de Gestão da
                                                    Privacidade.</a>
                                            </label>
                                        </div>
                                    </div>
                                    <div className='itemb itemPolitcasB'>
                                        <div className="form__botao">
                                            <button type="submit" value="Enviar">Enviar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <address className="formulario__endereco">
                            <p className="endereco__local">
                                Rua Godinho, 529<br />
                                4450-149 Matosinhos<br />
                                Portugal
                            </p>
                            <p className="endereco__email">
                                <a href='mailto:info@bellcanto.pt'>info@bellcanto.pt</a><br />
                                <span>+351 965 386 465 |</span> +351 917 385 130
                                <br />
                                <a href='https://www.instagram.com/bellcantomusic/' target="_blank" rel='noreferrer'>@bellcantomusic</a>
                            </p>
                            <img src={iconeClaro} alt="" className="endereco__img" />
                        </address>
                        {
                            loaderOn &&
                            <Loader />
                        }


                    </section>
            }




        </>
    );
};