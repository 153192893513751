
import React from 'react';
import { BrowserRouter as Router, Routes,  Route, } from 'react-router-dom';
import Home from 'Pages/Home/Home';
import Formacoes from 'Components/Formacoes/Formacoes';
import Interativo from 'Components/Interativo/Interativo';

import Item1 from 'Components/Interativo/MidiaModal/Item1';
import Item2 from 'Components/Interativo/MidiaModal/Item2';
import Item3 from 'Components/Interativo/MidiaModal/Item3';
import Item4 from 'Components/Interativo/MidiaModal/Item4';
import Item5 from 'Components/Interativo/MidiaModal/Item5';
import Item6 from 'Components/Interativo/MidiaModal/Item6';
import Item7 from 'Components/Interativo/MidiaModal/Item7';
import Item8 from 'Components/Interativo/MidiaModal/Item8';
import Item9 from 'Components/Interativo/MidiaModal/Item9';
import Item10 from 'Components/Interativo/MidiaModal/Item10';
import Item11 from 'Components/Interativo/MidiaModal/Item11';
import Item12 from 'Components/Interativo/MidiaModal/Item12';


function Routers() {

    return (
        
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="bellcanto" element={<Home />} >
                    {/* <Route path="formacoes" element={<Formacoes />} >
                        <Route path=":formacoesID" element={<Interativo />} />                       
                        
                    </Route> */}
                </Route>
                <Route path="formacao/duo-violino-e-cello" element={<Item1 />} />           
                <Route path="formacao/duo-violino-e-piano" element={<Item2 />} />           
                <Route path="formacao/trio-bellcanto-soprano" element={<Item3 />} />           
                <Route path="formacao/trio-bellcanto-tenor" element={<Item4 />} />           
                <Route path="formacao/quarteto-de-cordas" element={<Item5 />} />           
                <Route path="formacao/bellcanto-duo-de-vozes" element={<Item6 />} />           
                <Route path="formacao/coro-bellcanto" element={<Item7 />} />           
                <Route path="formacao/coro-quarteto-de-cordas-e-piano" element={<Item8 />} />           
                <Route path="formacao/opereta" element={<Item9 />} />           
                <Route path="formacao/duo-tenor-e-soprano" element={<Item10 />} />           
                <Route path="formacao/violino-solo" element={<Item11 />} />           
                <Route path="formacao/trio-soprano-ligeiro" element={<Item12 />} />

                <Route path="*" element={<Home />} />


            </Routes>
        


    )
}

export default Routers;