import Logo from 'Components/Logo/Logo'
import React from 'react'

import Menu from './Menu.js/Menu'


export default function Header({toggleIdioma, setToggleIdioma}) {
    return (

        <header className="header">
            <Menu 
                toggleIdioma={toggleIdioma}
                setToggleIdioma={setToggleIdioma}
            />
        </header>

    )
}
