import React from 'react'

export default function Litigios({ openLitigios, setOpenLitigios, toggleIdioma }) {

    function toggleLitigios() {
        setOpenLitigios(!openLitigios)
    }

    return (
        <div className="mapa__balao__container"
            onClick={() => toggleLitigios()}
        >
            {
                !toggleIdioma ?
                //Texto PT
                <div className="mapa__balao">
                    <h3 className="mapa__titulo">
                        RESOLUÇÃO ALTERNATIVA DE LITÍGIOS
                    </h3>
                    <p className="mapa__paragrafo">
                        Centro de Informação de Consumo e Arbitragem do Porto:<a href="http://www.cicap.pt" target="_blank" rel="noreferrer">www.cicap.pt</a>
                    </p>
                    <p className="mapa__paragrafo">
                        Plataforma Europeia de
                        Resolução de Litígios em linha: <a href="https://ec.europa.eu/" target="_blank" rel="noreferrer">ec.europa.eu</a>
                    </p>
                    <button className="mapa__botao"
                        onClick={() => toggleLitigios()}
                    ></button>
                </div>
                :
                //Texto EN
                <div className="mapa__balao">
                    <h3 className="mapa__titulo">
                        RESOLUÇÃO ALTERNATIVA DE LITÍGIOS
                    </h3>
                    <p className="mapa__paragrafo">
                        Centro de Informação de Consumo e Arbitragem do Porto:<a href="http://www.cicap.pt" target="_blank" rel="noreferrer">www.cicap.pt</a>
                    </p>
                    <p className="mapa__paragrafo">
                        Plataforma Europeia de
                        Resolução de Litígios em linha: <a href="https://ec.europa.eu/" target="_blank" rel="noreferrer">ec.europa.eu</a>
                    </p>
                    <button className="mapa__botao"
                        onClick={() => toggleLitigios()}
                    ></button>
                </div>

            }           
            
        </div>

    )
}
