import React, { useEffect, useState } from 'react'
import Carousel from 'react-elastic-carousel'

import banner01 from "assets/img/banner/cello.jpg"
import banner02 from "assets/img/banner/piano.jpg"
import banner03 from "assets/img/banner/violino.jpg"

import carroselLogo from "assets/img/logo.svg"

import { ContainerBanner } from './styles.js'


export default function Banner() {
    // handleAnswerChange(event){
    // 	if(event.key === 'y'){
    // 		alert('The sky is your starting point!')
    // }
    // 	else if (event.key === 'n') {
    // 		alert('The sky is your limit👀')
    // }

    const [proxima, setproxima] = useState()


    return (
        <>

            <ContainerBanner
                /* id='carousel' */>
                <Carousel

                    itemsToShow={1}
                    pagination={true}
                    /* showArrows={true} */
                    enableAutoPlay={true}
                    enableMouseSwipe={false}
                    enableSwipe={true}
                    autoPlaySpeed={4000}


                // controle mouse volume


                >


                    <div className="item" >
                        <div>
                            <img src={banner01} alt="Banner Bellcanto" />
                        </div>

                    </div>
                    <div className="item">
                        <div>
                            <img src={banner02} alt="Banner Bellcanto" />

                        </div>
                    </div>
                    <div className="item">
                        <div>
                            <img src={banner03} alt="Banner Bellcanto" />
                        </div>
                    </div>
                </Carousel>

                <div className="content">
                    <img className="carrosel__logo" src={carroselLogo} alt="Bellcanto" />
                </div>

                <div id="wp-ratedWA">
                    <a target="_blank" href="https://www.casamentos.pt/musica-casamento/bellcanto--e148031" rel="nofollow" title="Bellcanto, vencedor Wedding Awards 2023 Casamentos.pt ">
                        <img width="125" height="125" alt="Bellcanto, vencedor Wedding Awards 2023 Casamentos.pt " id="wp-ratedWA-img-2023" src="https://cdn1.casamentos.pt/img/badges/2023/badge-weddingawards_pt_PT.jpg" />
                    </a>
                </div>
                <script type="text/javascript" src="https://cdn1.casamentos.pt/_js/wp-rated.js?v=4"></script>
                <script>wpShowRatedWAv3('148031','2023');</script>

            </ContainerBanner>
        </>
    )
}
