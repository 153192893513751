import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

const [voltarSlide, proximoSlide] = document.querySelectorAll('.rec.rec-arrow')

window.addEventListener('keyup', (e) => {
  const botao = e.key
  if (botao === 'ArrowRight') {
    proximoSlide.click()
  }
  else if (botao === 'ArrowLeft') {
    voltarSlide.click()
  }
})
