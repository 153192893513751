import React, { useState } from 'react'
import Banner from 'Components/Banner/Banner'
import Destaque from 'Components/Destaque/Destaque'
import Formacoes from 'Components/Formacoes/Formacoes'
import OqFazemos from 'Components/OqFazemos/OqFazemos'
import Sobre from 'Components/Sobre/Sobre'
import Header from 'Components/Header/Header'
import ContactUs from 'Components/Contacto/Contacto'
import Footer from 'Components/Footer/Footer'
import Mapa from 'Components/Mapa/Mapa'
import { Outlet } from 'react-router-dom'


export default function Home() {
    const [politicaPriv, setPoliticaPriv] = useState(false)
    const [toggleIdioma, setToggleIdioma] = useState(false)

    return (
        <>
            <Header 
                toggleIdioma={toggleIdioma}
                setToggleIdioma={setToggleIdioma}
            />
            <main>
                <Banner />

                <section className="linha">
                    <Destaque 
                        toggleIdioma={toggleIdioma}                        
                    />
                    <div id="sectionSobre">
                        <Sobre 
                            toggleIdioma={toggleIdioma}                           
                        />
                    </div>

                </section>
                <div id="sectionFormacoes">
                    <Formacoes 
                        toggleIdioma={toggleIdioma}                        
                    />                   
                </div>
                <div id="sectionOqFazemos">
                    <OqFazemos 
                        toggleIdioma={toggleIdioma}                        
                    />
                </div>

                <section className="parallax">
                </section>
                <div id="sectionContactos">
                    <ContactUs
                        politicaPriv={politicaPriv}
                        setPoliticaPriv={setPoliticaPriv}
                        toggleIdioma={toggleIdioma}                        
                    />
                </div>

                <Mapa />
                <Footer
                    politicaPriv={politicaPriv}
                    setPoliticaPriv={setPoliticaPriv}
                    toggleIdioma={toggleIdioma}                    
                />
            </main>




        </>
    )
}
