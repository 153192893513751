import React from 'react'

export default function PoliticaPrivacidade({ politicaPriv, setPoliticaPriv,  toggleIdioma}) {

    return (
        <>
            {
                !toggleIdioma ?
                //Texto PT
                <div className="politicaPrivacidade">
                    <button className="botaoFechar politicaPrivacidade__botao" type="button"
                        onClick={() => setPoliticaPriv(!politicaPriv)}
                    ></button>
                    <h1 className="politicaPrivacidade__titulo">BELLCANTO</h1>
                    <h2 className="politicaPrivacidade__subTitulo">Política da Gestão da privacidade</h2>
                    <ul className="politicaPrivacidade__regras">
                        <li className="regras__container">
                            <h3 className="regras__titulo">1. ÂMBITO</h3>
                            <p className="regras__regra">
                                A Política de Gestão da Privacidade apresenta os compromissos da Bellcanto em relação à gestão da privacidade de dados pessoais dos titulares, assim como para a conformidade para com o Regulamento Geral de Proteção de Dados, identificado com sendo o REGULAMENTO (UE) 2016/679 DO PARLAMENTO EUROPEU E DO CONSELHO de 27 de abril de 2016.<br />
                                Tendo em conta o inventário de dados pessoais que a Bellcanto. mantém atualizado, todos os dados que são considerados privados e/ou sensíveis são geridos de acordo com os requisitos do Regulamento Geral de Proteção de Dados por forma a assegurar o cumprimento dos direitos dos respetivos titulares.
                            </p>
                        </li>
                        <li className="regras__container">
                            <h3 className="regras__titulo">2. CONFIDENCIALIDADE E PRIVACIDADE DOS DADOS PESSOAIS</h3>
                            <p className="regras__regra">
                                São considerados titulares de dados pessoais os clientes corporativos por contrato, clientes através de ferramentas Web, colaboradores internos e os fornecedores e prestadores de serviços (subcontratantes).<br />
                                Os dados pessoais e/ou sensíveis colocados à custódia Bellcanto são acedidos por colaboradores formalmente autorizados a realizar tais tarefas.<br />
                                Os dados são utilizados apenas para as atividades que foram previamente autorizadas pelos titulares de dados pessoais através de consentimento previamente informado e livre.<br />
                                Assim, no enquadramento do compromisso de garantia de privacidade dos dados pessoais, é também assegurada a respetiva confidencialidade.<br />
                                A garantia de confidencialidade é realizada através da celebração, com os colaboradores da Bellcanto, de acordos para proteção de dados pessoais a que acedem e tratam no decurso das suas atividades profissionais.
                            </p>
                        </li>
                    </ul>
                </div>

                :

                //Texto EN
                <div className="politicaPrivacidade">
                    <button className="botaoFechar politicaPrivacidade__botao" type="button"
                        onClick={() => setPoliticaPriv(!politicaPriv)}
                    ></button>
                    <h1 className="politicaPrivacidade__titulo">BELLCANTO</h1>
                    <h2 className="politicaPrivacidade__subTitulo">Política da Gestão da privacidade</h2>
                    <ul className="politicaPrivacidade__regras">
                        <li className="regras__container">
                            <h3 className="regras__titulo">1. ÂMBITO</h3>
                            <p className="regras__regra">
                                A Política de Gestão da Privacidade apresenta os compromissos da Bellcanto em relação à gestão da privacidade de dados pessoais dos titulares, assim como para a conformidade para com o Regulamento Geral de Proteção de Dados, identificado com sendo o REGULAMENTO (UE) 2016/679 DO PARLAMENTO EUROPEU E DO CONSELHO de 27 de abril de 2016.<br />
                                Tendo em conta o inventário de dados pessoais que a Bellcanto. mantém atualizado, todos os dados que são considerados privados e/ou sensíveis são geridos de acordo com os requisitos do Regulamento Geral de Proteção de Dados por forma a assegurar o cumprimento dos direitos dos respetivos titulares.
                            </p>
                        </li>
                        <li className="regras__container">
                            <h3 className="regras__titulo">2. CONFIDENCIALIDADE E PRIVACIDADE DOS DADOS PESSOAIS</h3>
                            <p className="regras__regra">
                                São considerados titulares de dados pessoais os clientes corporativos por contrato, clientes através de ferramentas Web, colaboradores internos e os fornecedores e prestadores de serviços (subcontratantes).<br />
                                Os dados pessoais e/ou sensíveis colocados à custódia Bellcanto são acedidos por colaboradores formalmente autorizados a realizar tais tarefas.<br />
                                Os dados são utilizados apenas para as atividades que foram previamente autorizadas pelos titulares de dados pessoais através de consentimento previamente informado e livre.<br />
                                Assim, no enquadramento do compromisso de garantia de privacidade dos dados pessoais, é também assegurada a respetiva confidencialidade.<br />
                                A garantia de confidencialidade é realizada através da celebração, com os colaboradores da Bellcanto, de acordos para proteção de dados pessoais a que acedem e tratam no decurso das suas atividades profissionais.
                            </p>
                        </li>
                    </ul>
                </div>
            }
          
        </>

    )
}
