import Logo from 'Components/Logo/Logo';
import React, { useState, useEffect } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import menuHamburger from '../../../assets/img/menu-hamburger.svg'
import x from '../../../assets/img/x_2.svg'

export default function Menu({ toggleIdioma, setToggleIdioma }) {

    const [toggle, setToggle] = useState(false);
    const [idIdioma, setIdIdioma] = useState(localStorage.getItem("Idioma"))

    useEffect(() => {
        if (toggle) {

            document.body.classList.add('pausar-scroll')
            return
        }
        document.body.classList.remove('pausar-scroll')
    }, [toggle])

    useEffect(() => {

        if (toggleIdioma) {
            localStorage.setItem("Idioma", "EN")
        } else {
            localStorage.setItem("Idioma", "PT")
        }


    }, [toggleIdioma])

    function retornaScroll() {
        document.body.classList.remove('pausar-scroll')
        setToggle(!toggle)
    }

    function idioma() {
        setToggleIdioma(!toggleIdioma)
    }
    return (
        <>  <div className='contianer-header'>
            <Logo />
            <nav>
                {
                    !toggleIdioma ?
                        // Texto em PT
                        <ul className="header__navegacao">
                            <li className="navegacao__item">
                                <AnchorLink href='#sectionSobre'>Sobre nós</AnchorLink>
                            </li>
                            <li className="navegacao__item">
                                <AnchorLink href='#sectionFormacoes'><span>Formações</span></AnchorLink>
                            </li>
                            <li className="navegacao__item">
                                <AnchorLink href='#sectionOqFazemos'>O que fazemos</AnchorLink>
                            </li>
                            <li className="navegacao__item">
                                <AnchorLink href='#sectionContactos'><span>Contactos</span></AnchorLink>
                            </li>
                            <li className="navegacao__item">
                                <button
                                    type='button'
                                    onClick={() => idioma()}
                                >EN</button>
                            </li>
                        </ul>

                        :
                        <ul className="header__navegacao">
                            <li className="navegacao__item">
                                <AnchorLink href='#sectionSobre'>About us</AnchorLink>
                            </li>
                            <li className="navegacao__item">
                                <AnchorLink href='#sectionFormacoes'><span>Formations</span></AnchorLink>
                            </li>
                            <li className="navegacao__item">
                                <AnchorLink href='#sectionOqFazemos'>What we do</AnchorLink>
                            </li>
                            <li className="navegacao__item">
                                <AnchorLink href='#sectionContactos'><span>Contacts</span></AnchorLink>
                            </li>
                            <li className="navegacao__item">
                                <button
                                    type='button'
                                    onClick={() => idioma()}
                                >PT</button>
                            </li>
                        </ul>


                }



            </nav>

        </div>
            <button type="button" className="btn-hamburguer"
                onClick={() => {
                    const munu = document.querySelector('.menu-hamburger__navegacao')

                    if (munu) {
                        munu.style.animation = 'menu-animation-close .5s ease-in-out';
                        setTimeout(() => {
                            setToggle(!toggle)
                        }, 500);
                    }
                    else {
                        setToggle(!toggle)
                    }
                }}
            >
                <img className="icon-hamburguer" src={(toggle) ? x : menuHamburger} alt="" />
            </button>

            <nav className="menu-hamburger__nav">


                {
                    toggle &&
                    <ul className="menu-hamburger__navegacao">
                        {
                            !toggleIdioma ?
                                //Texto PT
                                <>
                                    <li className="navegacao__item">
                                        <AnchorLink onClick={retornaScroll} href='#sectionSobre' className="menu-hamburger__item">Sobre nós</AnchorLink>
                                    </li>
                                    <li className="navegacao__item">
                                        <AnchorLink onClick={retornaScroll} href='#sectionFormacoes' className="menu-hamburger__item"><span>Formações</span></AnchorLink>
                                    </li>
                                    <li className="navegacao__item">
                                        <AnchorLink onClick={retornaScroll} href='#sectionOqFazemos' className="menu-hamburger__item">O que fazemos</AnchorLink>
                                    </li>
                                    <li className="navegacao__item">
                                        <AnchorLink onClick={retornaScroll} href='#sectionContactos' className="menu-hamburger__item"><span>Contactos</span></AnchorLink>
                                    </li>
                                    <li className="navegacao__item">
                                        <a onClick={() => idioma()} href='#'><span>EN</span></a>
                                    </li>
                                </>

                            :
                                <>
                                    <li className="navegacao__item">
                                        <AnchorLink onClick={retornaScroll} href='#sectionSobre' className="menu-hamburger__item">About us</AnchorLink>
                                    </li>
                                    <li className="navegacao__item">
                                        <AnchorLink onClick={retornaScroll} href='#sectionFormacoes' className="menu-hamburger__item"><span>Formations</span></AnchorLink>
                                    </li>
                                    <li className="navegacao__item">
                                        <AnchorLink onClick={retornaScroll} href='#sectionOqFazemos' className="menu-hamburger__item">What we do</AnchorLink>
                                    </li>
                                    <li className="navegacao__item">
                                        <AnchorLink onClick={retornaScroll} href='#sectionContactos' className="menu-hamburger__item"><span>Contacts</span></AnchorLink>
                                    </li>
                                    <li className="navegacao__item">
                                        <a onClick={() => idioma()} href='#'><span>PT</span></a>
                                    </li>
                                </>

                        }
                    </ul>

                }

            </nav>

        </>

    )
}
