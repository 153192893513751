import Cookies from 'Components/Cookies/Cookies'
import Interativo from 'Components/Interativo/Interativo'
import React, { useState, useEffect } from 'react'
import { Link, useParams } from "react-router-dom";

import Cursos from 'Utils/Cursos'


export default function Formacoes({toggleIdioma}) {
    const [getMidias, setGetMidias] = useState(Cursos)
    const [idSelect, setIdSelect] = useState("")
    const [valorMidias, setValorMidias] = useState([])
    const [valorMusicas, setValorMusicas] = useState([])
    const [toggleInterativo, setToogleInterativo] = useState(false)

    const params = useParams();

    useEffect(() => {
        if (toggleInterativo) {

            document.body.classList.add('pausar-scroll')
            return
        }
        document.body.classList.remove('pausar-scroll')
    }, [toggleInterativo])

    function midiaP(id) {

        setIdSelect(getMidias.filter(statusID => statusID.Id === id))

        setValorMidias({
            "Id": getMidias[id].Id,
            "Nome1": getMidias[id].Nome1,
            "Nome2": getMidias[id].Nome2,
            "Nome1EN": getMidias[id].Nome1EN,
            "Nome2EN": getMidias[id].Nome2EN,
            "URL": getMidias[id].URL,
            "Descricao": getMidias[id].Descricao,
            "DescricaoEN": getMidias[id].DescricaoEN,
            "Imagem": getMidias[id].Imagem,
            "MidiaVideo": getMidias[id].MidiaVideo,
            "MidiaAudio": getMidias[id].MidiaAudio,
            "Video": getMidias[id].Video,
        })

        setValorMusicas(getMidias[id].Musicas)

        setToogleInterativo(!toggleInterativo)

    }

    return (
        <>

            {
                !toggleIdioma ?
                //Texto PT
                <section className="formacoes">
                    <h3>{params.videoID}</h3>
                    <h2 className="formacoes__titulo titulo-2">
                        As nossas formações
                    </h2>
                    <p className="formacoes__paragrafo">
                        Para tornar o sonho em realidade, reunimos várias opções musicais para o seu evento.
                        Deixe-se invadir pelas nossas melodias.
                    </p>
                    <ul className="formacoes__container">
                        {
                            getMidias.map((mid, index) => (
                                <li className="formacoes__formacao" key={index}>
                                    <Link to={"formacao/" + mid.URL}>
                                        <img
                                            className="formacao__img"
                                            src={mid.Imagem} alt={mid.Nome1 + " " + mid.Nome2}
                                        />
                                        <p className="formacao__texto">{mid.Nome1 + " " + mid.Nome2}</p>
                                    </Link>
                                </li>
                            ))
                        }
                    </ul>
                </section>
                :
                //Texto EN
                <section className="formacoes">
                    <h3>{params.videoID}</h3>
                    <h2 className="formacoes__titulo titulo-2">
                        As nossas formações
                    </h2>
                    <p className="formacoes__paragrafo">
                        Para tornar o sonho em realidade, reunimos várias opções musicais para o seu evento.
                        Deixe-se invadir pelas nossas melodias.
                    </p>
                    <ul className="formacoes__container">
                        {
                            getMidias.map((mid, index) => (
                                <li className="formacoes__formacao" key={index}>
                                    <Link to={"formacao/" + mid.URL}>
                                        <img
                                            className="formacao__img"
                                            src={mid.Imagem} alt={mid.Nome1EN + " " + mid.Nome2EN}
                                        />
                                        <p className="formacao__texto">{mid.Nome1EN + " " + mid.Nome2EN}</p>
                                    </Link>
                                </li>
                            ))
                        }
                    </ul>
                </section>
            }
           

            {/* {
                toggleInterativo &&
                <>
                    
                        <div
                            className="overlay"
                            onClick={() => setToogleInterativo(!toggleInterativo)}
                        ></div>
                        <div className="container__Midias">
                        
                            <Interativo
                                valorMidias={valorMidias}
                                valorMusicas={valorMusicas}
                                toggleInterativo={toggleInterativo} setToogleInterativo={setToogleInterativo}
                                toggleIdioma={toggleIdioma}
                            />


                        </div>
                   
                </>
            } */}

            {/* <Router>
      <Link to="interativo">interativo</Link>
        <Routes>
            
          <Route exact path="/interativo" element={Interativo}/>
         
        </Routes>
     
    </Router> */}

        </>
    )
}