import React from "react";
import "assets/css/style.css"
// import Home from "Pages/Home/Home";
import { BrowserRouter as Router } from 'react-router-dom'
import Routers from "./Routes"

function App() {
  return (
    <Router>
      <Routers />
    </Router>
  );
}

export default App;
