import React from 'react'

export default function Destaque({toggleIdioma}) {
    return (
        <>
            {
                !toggleIdioma ?
                //Texto PT
                <div className="introducao">
                    <h1 className="introducao__titulo">
                        Toque aquela <span> música </span> que ouvimos quando nos encontramos pela <span> primeira vez...</span>
                    </h1>
                    <p className="introducao__paragrafo">
                        A música é a expressão da alma humana. Somos uma equipa focada na missão de exprimir várias emoções e
                        sentimentos.
                    </p>
                </div>

                :

                //Texto EN
                <div className="introducao">
                    <h1 className="introducao__titulo">
                        Toque aquela <span> música </span> que ouvimos quando nos encontramos pela <span> primeira vez...</span>
                    </h1>
                    <p className="introducao__paragrafo">
                        A música é a expressão da alma humana. Somos uma equipa focada na missão de exprimir várias emoções e
                        sentimentos.
                    </p>
                </div>
            }
          
        </>


    )
}
