import React from 'react'

import iconeSVG from 'assets/img/icone.svg'

export default function OqFazemos({toggleIdioma}) {
    return (
        <>
            {
                !toggleIdioma ?
                //Texto PT
                <section className="oque-fazemos">
                    <div className='oque-fazemos-container'>
                        <h2 className="titulo-2 oque-fazemos__titulo">
                            O que fazemos
                        </h2>
                        <p className="oque-fazemos__paragrafo">
                            Bellcanto está vocacionado para a realização de momentos musicais em todo o tipo de cerimónias protocolares,
                            eventos empresariais, congressos, casamentos (na igreja e civis) e missas de batizados e bodas. Esta equipa
                            promete proporcionar momentos personalizados e adaptados a cada cliente.

                        </p>
                        <p className="oque-fazemos__paragrafo">
                            A versatilidade dos grupos permite adaptar-se facilmente a vários ambientes e circunstâncias e, deste modo,
                            as formações estão preparadas para executar vários géneros de repertório, desde os grandes clássicos, hinos,
                            Gospel até à música pop comercial atual. Bellcanto não tem um repertório rígido e único, adaptando os temas
                            musicais em função dos desejos dos organizadores das cerimónias, sempre com a capacidade de criar novos
                            arranjos musicais. Neste sentido, encontrar-se-á nas produções musicais todo um trabalho de adaptação e
                            recriação de melodias e ritmos das músicas.
                        </p>
                        <div className='oque-fazemos__img'>
                            <img src={iconeSVG} alt="" />
                        </div>
                    </div>
                </section>

                :
                //Texto EN
                <section className="oque-fazemos">
                    <div className='oque-fazemos-container'>
                        <h2 className="titulo-2 oque-fazemos__titulo">
                            O que fazemos
                        </h2>
                        <p className="oque-fazemos__paragrafo">
                            Bellcanto está vocacionado para a realização de momentos musicais em todo o tipo de cerimónias protocolares,
                            eventos empresariais, congressos, casamentos (na igreja e civis) e missas de batizados e bodas. Esta equipa
                            promete proporcionar momentos personalizados e adaptados a cada cliente.

                        </p>
                        <p className="oque-fazemos__paragrafo">
                            A versatilidade dos grupos permite adaptar-se facilmente a vários ambientes e circunstâncias e, deste modo,
                            as formações estão preparadas para executar vários géneros de repertório, desde os grandes clássicos, hinos,
                            Gospel até à música pop comercial atual. Bellcanto não tem um repertório rígido e único, adaptando os temas
                            musicais em função dos desejos dos organizadores das cerimónias, sempre com a capacidade de criar novos
                            arranjos musicais. Neste sentido, encontrar-se-á nas produções musicais todo um trabalho de adaptação e
                            recriação de melodias e ritmos das músicas.
                        </p>
                        <div className='oque-fazemos__img'>
                            <img src={iconeSVG} alt="" />
                        </div>
                    </div>
                </section>
            }            
        </>

    )
}
