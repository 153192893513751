import React from 'react'

import logoBellcanto from 'assets/img/logo-gold.svg'

export default function Logo() {
    return (
        <a href="#">
            <img className="header__logo" src={logoBellcanto} alt="logo bellcanto" />
        </a>
    )
}
