import imgCursos from 'assets/img/formacoes/duo-violin-e-cello.png'

import duoViolinoPiano from 'assets/audio/duo-violino-e-piano.mp3'
import duoViolinoPiano2 from 'assets/audio/duo-violino-e-piano.wav'

import quarTetoCodas from 'assets/audio/Quarteto-de-cordas.mp3'
import quarTetoCodas2 from 'assets/audio/Quarteto-de-cordas2.mp3'

import trioSoprano from 'assets/audio/Trio-Soprano.mp3'
import trioSoprano2 from 'assets/audio/Trio-Soprano-Ave-Maria-Master-1.wav'

import trioTenor from 'assets/audio/Trio-Tenor-You-are-the-reason-Master-1-1.wav'
import trioTenor2 from 'assets/audio/Trio-Tenor-You-raise-me-up-Master-1-2.wav'



export default function Cursos() {
    return (
        [
            {
                "ID": "1",
                "Ordem" : "0",
                "Nome1":"Duo Violino ",
                "Nome2": "& Cello",
                "Nome1EN":"Duo Violino ",
                "Nome2EN": "& Cello",
                "URL" : "duo-violino-e-cello",
                "Imagem": "https://www.bellcanto.pt/img/01_Violin_Cello.jpg",
                "Descricao": "Um duo capaz de reproduzir as mais belas melodias que tanto gosta.",
                "DescricaoEN": "Um duo capaz de reproduzir as mais belas melodias que tanto gosta.",
                "MidiaVideo": true,
                "MidiaAudio" : false,
                "Video" : "https://www.youtube.com/embed/YMapYn2GQ_Q"                
            },
            {
                "ID": "2",
                "Ordem" : "1",
                "Nome1":"Duo Violino ",
                "Nome2": "& Piano",
                "Nome1EN":"Duo Violino ",
                "Nome2EN": "& Piano",
                "URL" : "duo-violino-e-piano",
                "Imagem":"https://www.bellcanto.pt/img/02_Duo-Violino-Piano.jpg",
                "Descricao": "A versatilidade deste grupo permite explorar vários géneros musicais, existe a possibilidade da pianista interpretar alguns temas com voz, convertendo o duo num trio.",
                "DescricaoEN": "A versatilidade deste grupo permite explorar vários géneros musicais, existe a possibilidade da pianista interpretar alguns temas com voz, convertendo o duo num trio.",
                "MidiaVideo": true,
                "MidiaAudio" : true,
                "Video" : "https://www.youtube.com/embed/V2pVGdcuES8",
                "Musicas" : [
                    {
                        "Nome": "Duo Violino & Piano",
                        "Audio": duoViolinoPiano,
                    },
                    {
                        "Nome": "Duo Violino & Piano",
                        "Audio": duoViolinoPiano2,
                    }
                ]                
            },
            
            {
                "ID": "3",
                "Ordem" : "2",
                "Nome1":"Trio Bellcanto",
                "Nome2": "Soprano",
                "Nome1EN":"Trio Bellcanto",
                "Nome2EN": "Soprano",
                "URL" : "trio-bellcanto-soprano",
                "Imagem": "https://www.bellcanto.pt/img/03_Trio-Bellcanto-Soprano.jpg",
                "Descricao": "Trio composto por uma Soprano, Violoncelo e piano. Um perfeito equilíbrio entre as vozes.",
                "DescricaoEN": "Trio composto por uma Soprano, Violoncelo e piano. Um perfeito equilíbrio entre as vozes.",
                "MidiaVideo": true,
                "MidiaAudio" : true,
                "Video" : "https://www.youtube.com/embed/PVb9S6YwcI0",
                "Musicas" : [
                    {
                        "Nome": "Trio Soprano",
                        "Audio": trioSoprano,
                    },
                    {
                        "Nome": "Ave Maria",
                        "Audio": trioSoprano2,
                    }
                ]  
            },
            {
                "ID": "4",
                "Ordem" : "3",
                "Nome1":"Trio Bellcanto",
                "Nome2": "Tenor",
                "Nome1EN":"Trio Bellcanto",
                "Nome2EN": "Tenor",
                "URL" : "trio-bellcanto-tenor",
                "Imagem": "https://www.bellcanto.pt/img/04_Trio-Bellcanto-Tenor.jpg",
                "Descricao": "Trio composto por um Tenor, violino e piano. O contraste entre alinhas melódicas do Tenor e do violino caracteriza este grupo.",
                "DescricaoEN": "Trio composto por um Tenor, violino e piano. O contraste entre alinhas melódicas do Tenor e do violino caracteriza este grupo.",
                "MidiaVideo": true,
                "MidiaAudio" : true,
                "Video" : "https://www.youtube.com/embed/tB2T6xIwpxY",
                "Musicas" : [
                    {
                        "Nome": "You are the reason",
                        "Audio": trioTenor,
                    },
                    {
                        "Nome": "You raise me up",
                        "Audio": trioTenor2,
                    }
                ]  
            },
            {
                "ID": "5",
                "Ordem" : "4",
                "Nome1":"Quarteto",
                "Nome2": "de Cordas",
                "Nome1EN":"Quarteto",
                "Nome2EN": "de Cordas",
                "URL" : "quarteto-de-cordas",
                "Imagem": "https://www.bellcanto.pt/img/05_Quarteto-de-Cordas.jpg",
                "Descricao": "O quarteto de cordas constituído por dois violinos, viola e violoncelo, é considerado como a formação perfeita de expressão artística na música clássica. Capazes de interpretar tudo aquilo que possa imaginar, quem sabe, talvez mais...",
                "DescricaoEN": "O quarteto de cordas constituído por dois violinos, viola e violoncelo, é considerado como a formação perfeita de expressão artística na música clássica. Capazes de interpretar tudo aquilo que possa imaginar, quem sabe, talvez mais...",
                "MidiaVideo": false,
                "MidiaAudio" : true,
                "Video" : "https://www.youtube.com/embed/eh-72yBP7sw",
                "Musicas" : [
                    {
                        "Nome": "Quarteto de Cordas",
                        "Audio": quarTetoCodas,
                    },
                    {
                        "Nome": "Quarteto de Cordas",
                        "Audio": quarTetoCodas2,
                    }
                ] 
            },
            {
                "ID": "6",
                "Ordem" : "5",
                "Nome1":"Bellcanto Duo",
                "Nome2": "de Vozes",
                "Nome1EN":"Bellcanto Duo",
                "Nome2EN": "de Vozes",
                "URL" : "bellcanto-duo-de-vozes",
                "Imagem": "https://www.bellcanto.pt/img/06_Duo-Bellcanto-de-Vozes.jpg",
                "Descricao": "Duo composto por um tenor,  uma soprano um piano e violino, a união perfeita entre vozes.",
                "DescricaoEN": "Duo composto por um tenor,  uma soprano um piano e violino, a união perfeita entre vozes.",
                "MidiaVideo": true,
                "MidiaAudio" : false,
                "Video" : "https://www.youtube.com/embed/eg-9_Z8jfFE"                  
            },
            {
                "ID": "7",
                "Ordem" : "6",
                "Nome1":"Coro Bellcanto",
                "Nome2": "& Piano",
                "Nome1EN":"Coro Bellcanto",
                "Nome2EN": "& Piano",
                "URL" : "coro-bellcanto",
                "Imagem": "https://www.bellcanto.pt/img/07_Coro-Bellcanto.jpg",
                "Descricao": "4 vozes masculinas + 4 vozes femininas acompanhadas ao piano.",
                "DescricaoEN": "4 vozes masculinas + 4 vozes femininas acompanhadas ao piano.",
                "MidiaVideo": true,
                "MidiaAudio" : false,
                "Video" : "https://www.youtube.com/embed/3H-A471-5QY"                
            },
            {
                "ID": "8",
                "Ordem" : "7",
                "Nome1":" Coro Bellcanto, ",
                "Nome2": "Quarteto de Cordas & Piano",
                "Nome1EN":"Coro Bellcanto,",
                "Nome2EN": "Quarteto de Cordas & Piano",
                "URL" : "coro-quarteto-de-cordas-e-piano",
                "Imagem": "https://www.bellcanto.pt/img/08_Coro-Bellcanto_Quarteto-de-cordas_piano.jpg",
                "Descricao": "4 vozes masculinas + 4 vozes femininas + quarteto de cordas + piano. A sua sonoridade imponente assemelha-se a uma verdadeira orquestra, o seu impacto não passará despercebido. ",
                "DescricaoEN": "4 vozes masculinas + 4 vozes femininas + quarteto de cordas + piano. A sua sonoridade imponente assemelha-se a uma verdadeira orquestra, o seu impacto não passará despercebido. ",
                "MidiaVideo": true,
                "MidiaAudio" : false,
                "Video" : "https://www.youtube.com/embed/aH1s6pG1ELE"                         
            },
            {
                "ID": "9",
                "Ordem" : "8",
                "Nome1":"Opereta",
                "Nome2": "",
                "Nome1EN":"Opereta",
                "Nome2EN": "",
                "URL" : "opereta",
                "Imagem": "https://www.bellcanto.pt/img/09_Opereta.jpg",
                "Descricao": "Camuflado(s) entre os convidados, eis que surge(m) num momento inesperado interpretando as mais conhecidas árias de opera.",
                "DescricaoEN": "Camuflado(s) entre os convidados, eis que surge(m) num momento inesperado interpretando as mais conhecidas árias de opera.",
                "MidiaVideo": false,
                "MidiaAudio" : false                
            },
            {
                "ID": "10",
                "Ordem" : "9",
                "Nome1":"Duo Tenor",
                "Nome2": "& Soprano",
                "Nome1EN":"Duo Tenor",
                "Nome2EN": "& Soprano",
                "URL" : "duo-tenor-e-soprano",
                "Imagem": "https://www.bellcanto.pt/img/10_Duo-Tenor_Soprano.jpeg",
                "Descricao": "Duo composto por um tenor e uma soprano acompanhados por backing tracks personalizados para o momento.",
                "MidiaVideo": false,
                "MidiaAudio" : false                
            },
            {
                "ID": "11",
                "Ordem" : "10",
                "Nome1":"Violino",
                "Nome2": "Solo",
                "Nome1EN":"Violino",
                "Nome2EN": "Solo",
                "URL" : "violino-solo",
                "Imagem": "https://www.bellcanto.pt/img/11_Violino-Solo.jpg",
                "Descricao": "Conhecido pelas sua belas linhas melódicas, o violino é capaz de se adaptar a qualquer género musical ou ambiente, a versatilidade deste serviço preenche tudo aquilo que procura.",
                "MidiaVideo": true,
                "MidiaAudio" : true,
                "Video" : "https://www.youtube.com/embed/XiHW6jDpRbY",
                "Musicas" : [
                    {
                        "Nome": "Violino Solo",
                        "Audio": duoViolinoPiano,
                    },
                    {
                        "Nome": "Violino Solo",
                        "Audio": duoViolinoPiano2,
                    }
                ] 
            },
            {
                "ID": "12",
                "Ordem" : "11",
                "Nome1":"Trio Soprano",
                "Nome2": "Ligeiro",
                "Nome1EN":"Trio Soprano",
                "Nome2EN": "Ligeiro",
                "URL" : "trio-soprano-ligeiro",
                "Imagem": "https://www.bellcanto.pt/img/12_Trio-Soprano-Ligeiro.jpg",
                "Descricao": "Trio composto por uma Soprano, violoncelo e piano. Interpreta aqueles clássicos que tanto gosta.",
                "MidiaVideo": true,
                "MidiaAudio" : true,
                "Video" : "https://www.youtube.com/embed/vw0Dv1OYZ40",
                "Musicas" : [
                    {
                        "Nome": "Trio Soprano Ligeiro",
                        "Audio": trioSoprano,
                    },
                    {
                        "Nome": "Trio Soprano Ligeiro",
                        "Audio": trioSoprano2,
                    }
                ] 
            },
        ]
    )
        
    
}