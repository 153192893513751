import React, { useState, useEffect } from 'react'

import logoFooter from 'assets/img/logo-footer.svg'
import iconeFacebook from 'assets/img/icone-facebook.svg'
import iconeInstagram from 'assets/img/icone-instagram.svg'
import iconeYoutube from 'assets/img/icone_youtube.svg'
import livroReclamacoes from 'assets/img/livro-de-reclamacoes.svg'
import Litigios from 'Components/Litigios/Litigios'
import PoliticaPrivacidade from 'Components/PoliticaPrivacidade/PoliticaPrivacidade'
import Cookies from 'Components/Cookies/Cookies'
import ButtonScrollTop from 'Components/ButtonScrollTop/ButtonScrollTop'

export default function Footer({ politicaPriv, setPoliticaPriv, toggleIdioma }) {
    const [openLitigios, setOpenLitigios] = useState(false)

    function toggleLitigios() {
        setOpenLitigios(!openLitigios)
    }

    useEffect(() => {
        if (politicaPriv) {

            document.body.classList.add('pausar-scroll')
            return
        }
        document.body.classList.remove('pausar-scroll')
    }, [politicaPriv])

    useEffect(() => {
        if (openLitigios) {

            document.body.classList.add('pausar-scroll')
            return
        }
        document.body.classList.remove('pausar-scroll')
    }, [openLitigios])

    return (
        <>

            <footer className="footer">
                <div className="footer__cima">
                    <div className='item'>
                        <img src={logoFooter} alt="logo bellcanto" className="footer__img" />
                        <ul className="footer__redes-sociais">
                            <li className="redes-sociais__rede-social">
                                <a href="https://www.facebook.com/bellcantomusic" target="_blank" rel="noreferrer">
                                    <img src={iconeFacebook} alt="facebook" /></a>
                            </li>
                            <li className="redes-sociais__rede-social">
                                <a href="https://www.instagram.com/bellcantomusic/" target="_blank" rel="noreferrer">
                                    <img src={iconeInstagram} alt="instagram" />
                                </a>
                            </li>
                            <li className="redes-sociais__rede-social">
                                <a href="https://www.youtube.com/channel/UCArLVPoFguNU3sVpJh32yhg" target="_blank" rel="noreferrer">
                                    <img src={iconeYoutube} alt="Youtube" />
                                </a>
                            </li>
                        </ul>
                    </div>

                    {
                        !toggleIdioma ?
                        //Texto PT
                        <ul className="footer__links">
                            <li className="links__link">
                                <button type="button" onClick={() => toggleLitigios()}>
                                    Resolução de Litígios
                                </button>
                            </li>
                            <li className="links__link">
                                <button type="button"
                                    onClick={() => setPoliticaPriv(!politicaPriv)}
                                >Política de Privacidade</button>
                            </li>
                            <li className="links__link">
                                <a href="https://www.livroreclamacoes.pt/Inicio/" target="_blank" rel="noreferrer">
                                    <img className="links__livroReclamacoes" src={livroReclamacoes} alt="livro de reclamações" />
                                </a>
                            </li>
                        </ul>

                        :

                        //Texto EN
                        <ul className="footer__links">
                            <li className="links__link">
                                <button type="button" onClick={() => toggleLitigios()}>
                                    Resolução de Litígios
                                </button>
                            </li>
                            <li className="links__link">
                                <button type="button"
                                    onClick={() => setPoliticaPriv(!politicaPriv)}
                                >Política de Privacidade</button>
                            </li>
                            <li className="links__link">
                                <a href="https://www.livroreclamacoes.pt/Inicio/" target="_blank" rel="noreferrer">
                                    <img className="links__livroReclamacoes" src={livroReclamacoes} alt="livro de reclamações" />
                                </a>
                            </li>
                        </ul>

                    }                   


                </div>
                {
                    !toggleIdioma ?
                    //Texto PT

                    <div className="footer__direitos">
                        <p className="direitos__texto">
                            Bellcanto ® Todos os direitos reservados
                        </p>
                        <p className="direitos__texto made-by">
                            <a href="https://www.nor267.com/" target="_blank" rel="noreferrer">Made by Nor267</a>
                        </p>
                    </div>
                    :
                    //Texto EN

                    <div className="footer__direitos">
                        <p className="direitos__texto">
                            Bellcanto ® Todos os direitos reservados
                        </p>
                        <p className="direitos__texto made-by">
                            <a href="https://www.nor267.com/" target="_blank" rel="noreferrer">Made by Nor267</a>
                        </p>
                    </div>
                }
                

            </footer>
            <Cookies
                politicaPriv={politicaPriv}
                setPoliticaPriv={setPoliticaPriv}
                toggleIdioma={toggleIdioma}
            />

            {
                openLitigios &&
                <Litigios
                    openLitigios={openLitigios}
                    setOpenLitigios={setOpenLitigios}
                    toggleIdioma={toggleIdioma}
                />
            }

            {
                politicaPriv &&
                <PoliticaPrivacidade
                    politicaPriv={politicaPriv} setPoliticaPriv={setPoliticaPriv}
                    toggleIdioma={toggleIdioma}
                />
            }

            <ButtonScrollTop />

        </>
    )
}
