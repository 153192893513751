import React from 'react'
import imgSobre from 'assets/img/sobre.jpg'

export default function Sobre({toggleIdioma}) {
    return (
        <div id="sobre-nos" className="sobre-nos">
            <img src={imgSobre} alt="" className="sobre-nos__imagem" />

            {
                !toggleIdioma ?
                //Texto PT
                <div className='sobre-text'>
                    <h2 className="sobre-nos__titulo">Sobre nós</h2>
                    <div className="sobre-nos__paragrafos">
                        <p>Nada será deixado ao sabor do acaso!  PTPT
                        </p>
                        <p>A felicidade da vida está nos momentos únicos, especiais e inesquecíveis que
                            vivemos! Sabe-se que a música é a expressão da alma humana, usada em momentos de alegria. É neste
                            contexto
                            que nasce a Bellcanto, uma equipa focada na missão de exprimir várias emoções e sentimentos,
                            prestando
                            um
                            serviço de excelência que promova a satisfação dos nossos clientes. Com uma imagem sofisticada
                            procuramos
                            servir o nosso cliente com profissionalismo e dedicação, tornando cada instante singular. Apostamos
                            num
                            serviço personalizado e exclusivo, onde cada detalhe é preparado a pensar em si. Nada será deixado
                            ao
                            sabor
                            do acaso, porque cada momento será cuidadosamente projetado, numa fusão perfeita de melodias,
                            sensações
                            e
                            glamour.
                        </p>
                        <p>Este projeto nasce da vontade de dinamizar e valorizar eventos de Norte a Sul do
                            país. Trata-se de uma componente musical completamente única e adaptada que está ao seu alcance para
                            tornar
                            sonhos em realidade! No entanto, procura-se sempre criar um conceito exclusivo para cada evento,
                            totalmente
                            personalizado, tendo em conta os pormenores, as ideias, preferências e sugestões de cada pessoa que
                            nos
                            contacta.
                        </p>
                        <p>
                            Este projeto que cria uma simbiose entre a música ao vivo e a dinamização do evento tem como
                            objetivos:
                            valorizar e destacar a inovação, a paixão musical, a criatividade dos músicos envolvidos, oferecer
                            experiências efémeras e únicas aos noivos e convidados.
                        </p>
                    </div>

                </div>

                :
                //Texto EN
                <div className='sobre-text'>
                    <h2 className="sobre-nos__titulo">Sobre nós</h2>
                    <div className="sobre-nos__paragrafos">
                        <p>Nada será deixado ao sabor do acaso!  EN EN
                        </p>
                        <p>A felicidade da vida está nos momentos únicos, especiais e inesquecíveis que
                            vivemos! Sabe-se que a música é a expressão da alma humana, usada em momentos de alegria. É neste
                            contexto
                            que nasce a Bellcanto, uma equipa focada na missão de exprimir várias emoções e sentimentos,
                            prestando
                            um
                            serviço de excelência que promova a satisfação dos nossos clientes. Com uma imagem sofisticada
                            procuramos
                            servir o nosso cliente com profissionalismo e dedicação, tornando cada instante singular. Apostamos
                            num
                            serviço personalizado e exclusivo, onde cada detalhe é preparado a pensar em si. Nada será deixado
                            ao
                            sabor
                            do acaso, porque cada momento será cuidadosamente projetado, numa fusão perfeita de melodias,
                            sensações
                            e
                            glamour.
                        </p>
                        <p>Este projeto nasce da vontade de dinamizar e valorizar eventos de Norte a Sul do
                            país. Trata-se de uma componente musical completamente única e adaptada que está ao seu alcance para
                            tornar
                            sonhos em realidade! No entanto, procura-se sempre criar um conceito exclusivo para cada evento,
                            totalmente
                            personalizado, tendo em conta os pormenores, as ideias, preferências e sugestões de cada pessoa que
                            nos
                            contacta.
                        </p>
                        <p>
                            Este projeto que cria uma simbiose entre a música ao vivo e a dinamização do evento tem como
                            objetivos:
                            valorizar e destacar a inovação, a paixão musical, a criatividade dos músicos envolvidos, oferecer
                            experiências efémeras e únicas aos noivos e convidados.
                        </p>
                    </div>

                </div>

            }  
        </div>
    )
}
