import React, { useState, useEffect } from 'react'

export default function Cookies({ politicaPriv, setPoliticaPriv, toggleIdioma }) {
    const [getCookies, setGetCookies] = useState(true)

    useEffect(() => {
        if (localStorage.getItem("Cookies")) {
            setGetCookies(false)
        } else {
            setGetCookies(true)
        }

    }, [])

    function aceitoCookie() {
        setGetCookies(false)
        localStorage.setItem("Cookies", true)
    }

    return (
        <>
            {
                !toggleIdioma ?
                //Texto PT
                <>
                    {
                        getCookies &&
                        <section className="aceita-termos">
                            <div className="aceita-termos__textos">
                                <p className="aceita-termos__texto">
                                    Bellcanto utiliza cookies para otimizar a sua experiência neste site e para lhe
                                    oferecer serviços e comunicações a medida dos seus interesses. <br />
                                    Ao continuar a navegar está a concordar com a utilização de cookies neste site.
                                </p>
                            </div>
                            <div className="aceita-termos__botoes">
                                <button type='button' className="aceita-termos__botao"
                                    onClick={() => setPoliticaPriv(!politicaPriv)}
                                >Saber Mais</button>
                                <button type='button' className="aceita-termos__botao"
                                    onClick={() => aceitoCookie()}
                                >
                                    Aceito
                                </button>

                            </div>
                        </section>
                    }
                </>

                :

                //Texto EN
                <>
                    {
                        getCookies &&
                        <section className="aceita-termos">
                            <div className="aceita-termos__textos">
                                <p className="aceita-termos__texto">
                                    Bellcanto utiliza cookies para otimizar a sua experiência neste site e para lhe
                                    oferecer serviços e comunicações a medida dos seus interesses. <br />
                                    Ao continuar a navegar está a concordar com a utilização de cookies neste site.
                                </p>
                            </div>
                            <div className="aceita-termos__botoes">
                                <button type='button' className="aceita-termos__botao"
                                    onClick={() => setPoliticaPriv(!politicaPriv)}
                                >Saber Mais</button>
                                <button type='button' className="aceita-termos__botao"
                                    onClick={() => aceitoCookie()}
                                >
                                    Aceito
                                </button>

                            </div>
                        </section>
                    }
                </>
            }

        </>
    )
}
