import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

import Cursos from 'Utils/Cursos';

export default function Item5() {

    const [getMidias, setGetMidias] = useState(Cursos)
    const [valorMusicas, setValorMusicas] = useState([])
    const [valorMidias, setValorMidias] = useState([])
    const [idSelect, setIdSelect] = useState("")

    let navigate = useNavigate();

    function handleClick() {
        // navigate("/");
        window.history.back()
    }

    useEffect(() => {
        let pId = 4
        setIdSelect(getMidias.filter(statusID => statusID.Id === pId))
        setValorMidias({
            "Id": getMidias[pId].Id,
            "Nome1": getMidias[pId].Nome1,
            "Nome2": getMidias[pId].Nome2,
            "Nome1": getMidias[pId].Nome1EN,
            "Nome2": getMidias[pId].Nome2EN,
            "URL": getMidias[pId].URL,
            "Descricao": getMidias[pId].Descricao,
            "DescricaoEN": getMidias[pId].DescricaoEN,
            "Imagem": getMidias[pId].Imagem,
            "MidiaVideo": getMidias[pId].MidiaVideo,
            "MidiaAudio": getMidias[pId].MidiaAudio,
            "Video": getMidias[pId].Video,
        })

        setValorMusicas(getMidias[pId].Musicas)

    }, [getMidias])

    function converteData(numero) {
        const minutos = zeronaFrente(Math.trunc(numero / 60))
        const segundos = zeronaFrente(Math.trunc(numero % 60));
        const numeroString = `${minutos}:${segundos}`

        return numeroString
    }

    function zeronaFrente(numero) {
        if (numero < 10) return `0${numero}`
        return `${numero}`
    }


    function tocarPausar(event) {
        const botao = event.target
        const audio = botao.parentElement.querySelector('audio')
        const tempofinal = botao.parentElement.querySelector('.audio__tempo-total')
        const totalTempoAudio = Math.trunc(audio.duration)
        const barraProgresso = botao.parentElement.querySelector('progress')
        const listaAudios = event.target.parentElement.parentElement.parentElement.querySelectorAll('audio')



        console.log(listaAudios)
        listaAudios.forEach(_audio => {
            if (_audio === audio) return
            const botao = _audio.parentElement.querySelector('.audio__player-pause')
            _audio.pause()
            botao.classList.remove('pause')
            botao.classList.add('play')

        })


        const repetidor = setInterval(function () {
            const tempoAtual = Math.trunc(audio.currentTime)
            const tempoAtualElemento = botao.parentElement.querySelector('.audio__tempo-atual')
            barraProgresso.value = tempoAtual
            tempoAtualElemento.innerText = converteData(tempoAtual)
            setTimeout(function () {
                clearInterval(repetidor)
            }, 1000 * (totalTempoAudio - audio.currentTime))
        }, 1000)

        if (!audio.paused) {
            audio.pause()
            botao.classList.remove('pause')
            botao.classList.add('play')
            clearInterval(repetidor)
        }
        else {
            audio.play()
            botao.classList.remove('play')
            botao.classList.add('pause')

        }

        barraProgresso.max = totalTempoAudio

        tempofinal.innerText = converteData(totalTempoAudio)
    }



    // Controle de volume


    function volumeAumentar(event) {
        const containerAudio = event.target.parentElement.parentElement;
        const audio = containerAudio.querySelector('audio')
        const barras = containerAudio.querySelectorAll('.barra')
        audio.volume = Math.min(1, (audio.volume + 0.2))
        colorirBarras(barras, audio.volume)
    }

    function volumeDiminui(event) {
        const containerAudio = event.target.parentElement.parentElement;
        const audio = containerAudio.querySelector('audio')
        const barras = containerAudio.querySelectorAll('.barra')
        audio.volume = Math.max(0, (audio.volume - 0.2))
        colorirBarras(barras, audio.volume)
    }

    function colorirBarras(barras, volume) {
        console.log(volume)
        for (let barra of barras) {
            const volumeBarra = +barra.dataset.volume

            if (volumeBarra <= volume) {
                barra.style.backgroundColor = '#B7A386'
            }
            else {
                barra.style.backgroundColor = '#D6D6D6'
            }

        }

    }


    return (

        <section className="menu sectionMidia" onLoad={() => {
            const audios = document.querySelectorAll('audio')
            audios.forEach(audio => {
                audio.volume = 0.6
            })
        }}>
            <button type='button' className="menu__botaoFechar"
                onClick={handleClick}
            ></button>
            <div className="menu__dados">
                <div className="zIn">
                {
                        localStorage.getItem("Idioma") === "PT" &&
                        //Texto PT
                        <div className='container-text'>
                            <h2 className="menu__titulo titulo-2 titulo-violino">
                                {valorMidias.Nome1} <br />
                                <span>{valorMidias.Nome2}</span>
                            </h2>
                            <p className="menu__descricao">{valorMidias.Descricao} </p>
                        </div>
                    }

                    {
                        localStorage.getItem("Idioma") === "EN" &&
                        //Texto EN
                        <div className='container-text'>
                            <h2 className="menu__titulo titulo-2 titulo-violino">
                                {valorMidias.Nome1EN} <br />
                                <span>{valorMidias.Nome2EN}</span>
                            </h2>
                            <p className="menu__descricao">{valorMidias.DescricaoEN} </p>
                        </div>
                    }
F

                    {
                        valorMidias.MidiaVideo &&

                        <div className="videoWrapper">
                            <iframe
                                src={valorMidias.Video}
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            >
                            </iframe>

                        </div>
                    }


                    <ul className="munu__musicas">
                        {
                            valorMidias.MidiaAudio &&
                            <>
                                {
                                    valorMusicas.map((msc, index) => (
                                        <li className="musicas__musica" key={index}>
                                            <p className="musica__nome">{msc.Nome}</p>
                                            <div className="audio" >
                                                <button onClick={tocarPausar} className="audio__player-pause play"></button>
                                                <span className="audio__tempo audio__tempo-atual">00:00</span>
                                                <progress min="0" max="150" value="0" className="audio__progresso"></progress>
                                                <span className="audio__tempo audio__tempo-total">00:00</span>
                                                <audio src={msc.Audio}></audio>
                                                <div className="volume">
                                                    <button className="button" onClick={volumeDiminui}>–</button>
                                                    <div className="barras">
                                                        <span data-volume="0.2" className="barra barra1"></span>
                                                        <span data-volume="0.4" className="barra barra2"></span>
                                                        <span data-volume="0.6" className="barra barra3"></span>
                                                        <span data-volume="0.8" className="barra barra4"></span>
                                                        <span data-volume="1" className="barra barra5"></span>
                                                    </div>

                                                    <button className="button" onClick={volumeAumentar}>+</button>
                                                </div>
                                            </div>
                                        </li>
                                    ))
                                }

                            </>
                        }
                    </ul>
                </div>
            </div>
            <img src={valorMidias.Imagem} className="menu__imagem" alt="violão" />


        </section>

    )
}
