import styled from 'styled-components'

export const ContainerBanner = styled.section`
    position: relative;
    border-radius: 0 0 clamp(6.25rem, 4.733rem + 6.4725vw, 12.5rem) 0;
    overflow: hidden;
    .rec-slider-container{
        margin: 0 !important;

        .item{
            width: 100%;
            position: relative;
            height: calc(100vh - 100px);
            img{
                width: 100%;
                object-fit: cover; 
                
            }
            
            
        }
    }

    .rec.rec-pagination{
        margin-top: 0;
        position: absolute;
        bottom: 35px;
        z-index:10;
    }
    .rec-dot {        
        background-color: rgb(189, 189, 189);       
        height: 12px;
        width: 12px;  
        margin: 7px;
        border:none;
        box-shadow: none;
    }

    .rec-dot_active {        
        background-color: rgb(105, 105, 105);       
        height: 12px;
        width: 12px;
        margin: 7px;
        border:none;
        box-shadow: none;
    }

    .carrosel__logo {
        filter: drop-shadow(0px -5px 18px rgba(0, 0, 0, 0.3));
        pointer-events:none;
    }

    .rec-slider-container .item img.carrosel__logo{
        place-self: center;
        z-index: 1;
        height: clamp(2.5625rem, 0.6966rem + 7.9612vw, 10.25rem);
        width: clamp(18.75rem, 5.0971rem + 58.2524vw, 75rem);
       
    }

    .rec.rec-arrow {
        background-color: red;
        display: none;
    }

    .content{
        position: absolute;
        top: 0;
        left: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: clamp(18.75rem, 5.0971rem + 58.2524vw, 75rem);
        height: 100%;
        text-align: center;
        z-index: 8;
        transform: translateX(-50%); 
        pointer-events:none;
        
        
        h1, h2{
        color: #000;                    
        }   
        

        @media (max-width: 600px) {
           
    }
        
    }

    @media (max-width: 1950px) {
        .rec-slider-container .item{
            height: calc(100vh - 100px);

            img{
                height: calc(100vh - 100px);
            }
        }
    }
    
    @media (max-width: 900px) {
        .rec-slider-container .item{
            height: calc(100vh - 60px);

            img{
                height: calc(100vh - 60px);
            }
        }
    }

    #wp-ratedWA {
        position: absolute;
        top: 2rem;
        right: 2rem;
    }
    

    
     
   
`;