import React from 'react'
import { ReactBingmaps } from 'react-bingmaps';


export default function Mapa() {

    return (
        
        <div id="myMap" style={{position:"relative", width:"100%",height:"500px"}}>
        <ReactBingmaps 
            bingmapKey = "AjD-b20LeK4GMf_Xzo9kmhadGryHa2NMIcoK_ZeT2e6bzgwvSW4qFuFjxXcib4tq" 
            center = {[41.182989207622555, -8.688675030806824]}
            zoom = {17}
            mapTypeId = {"grayscale"}
            supportedMapTypes = {["grayscale", "aerial"]}
            navigationBarMode = {"compact"}
            pushPins = {[
                {"location":[41.182989207622555, -8.688675030806824], "option":{ color: '#B7A386' }}
            ]}
          > 
        </ReactBingmaps>
        </div>
    )
}

